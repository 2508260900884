body {
    margin: 0;
    font-size: 16px;
    Font-Family: 'Open Sans', Sans-Serif;
    color: #333333;
}

*, *::before, *::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

a:hover {
    cursor: url(/images/hand.png), pointer !important
}

.st {
    text-decoration: line-through;
}

.clearfix {
    content: "";
    clear: both;
    display: table;
}

h1, h2, h3, h4 {
    Font-Family: 'Open Sans', Sans-Serif;
    font-weight: bold;
    margin-top: 0;
}

h1 {
    margin: 0;
}

h2 {
    margin-bottom: 3.0rem;
    font-size: 3.8828125em;
    line-height: 6rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0em;
}

h3 {
    margin-bottom: 3.0rem;
    font-size: 3.90625em;
    line-height: 6rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0em;
}

h4 {
    margin-bottom: 3.0rem;
    font-size: 3.125em;
    line-height: 6rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0em;
}

p, .p {
    margin-bottom: 3.0rem;
    line-height: 2.1rem;
    font-size: 1.225rem;
}

.par {
    display: block;
}

.par:first-child {
    margin-bottom: 2em;
}

.par--block{
    margin-bottom: 2em;
}

@media screen and (max-width: 720px) {

    h2 {
        margin-bottom: 2.6rem;
        font-size: 2.318171em;
        line-height: 3.3rem;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0;
        margin-top: 2rem;
    }

    h3 {
        margin-bottom: 2.6rem;
        font-size: 2.798409999999999em;
        line-height: 5.2rem;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0;
    }

    p, .p {
        margin-bottom: 2.4rem;
        line-height: 1.8rem;
    }

    .par{
        display: inline;
        margin-right: .15em;
    }

    .par--block{
        margin-top: 1em;
        margin-bottom: 1em;
    }
}