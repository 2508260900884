.grid {
    display: grid;
    grid-template-columns: 57% 37%;
    grid-gap: 6%;
}

.about__avatar-col {
    float: right;
}

.about__avatar-wrap {
    max-width: 200px;
}

.about__avatar {
}

.about__avatar--inline {
    display: none;
    max-width: 41%;
    float: right;
    padding-left: 1em;
}

@media screen and (max-width: 1280px) {
    .grid {
        grid-template-columns: 67% 27%;
    }
}

@media screen and (max-width: 720px) {
    .grid {
        display: block;
    }

    .about__avatar {
        display: none;
    }

    .about__avatar--inline {
        display: block;
    }

    .about__avatar-col {
        float: none;
    }
}
