.hero {
    text-align: left;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4.5rem;
}

.hero--block{
    padding-top: 10vh;
    width: 92%;
}

.hero a {
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 1px;
}

.hero a:hover {
    color: #000;
    text-shadow: none;
}

.hero .st {
    color: slategrey;
}

@media screen and (max-width: 720px) {
    .hero {
        width: auto;
        padding-top: 0.5em;
        font-size: 3rem;
        line-height: 4.2rem;
    }
    .hero .pr{
        line-height: 2.4rem;
        font-size: 1.5rem;
    }
}
