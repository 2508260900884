.zoom-img img {
    cursor: zoom-in;
    display: block;
    max-width: 100%;
    margin: auto;
}

.zoom-img img.zoomed {
    cursor: zoom-out;
    z-index: 101;
}

.zoom-img .frame {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background: white;
    transform: translateZ(0);
    z-index: 100;
}