.lazy-wrap{
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.lazy--loading{
    opacity: 0;
    position: absolute;
    transition: opacity 300ms ease-out;
}

.lazy-placeholder{
    opacity: 1;
    transition: opacity 300ms ease-out;
}
.lazy-placeholder--hidden{
    opacity: 0;
    position: absolute;
    transition: opacity 300ms ease-in;
    pointer-events: none;
}