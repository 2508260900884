
.project-item__info-wrap {
    display: flex;
    width: 100%;
}
.project-item__info {
    flex: 1;
    border-left: 3px solid rgba(197, 217, 245, 0.28);
    padding-left: 1em;
}
.project-item__info > span{
    color: #999;
    margin-bottom: 4px;
    display: block;

}
.project-item__info > div{
    text-transform: capitalize;
}


@media screen and (max-width: 480px) {

    .project-item__info-wrap {
        display: block;
    }
    .project-item__info {
        padding-bottom: 1rem;
    }

}