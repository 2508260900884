header h1 {
    width: 300px;
    display: inline-block;
    margin: 1rem 0 0;
    font-size: 1rem;
    line-height: 1rem;
}

nav {
    float: right;
}

nav ul {
    list-style: none;
    margin: 1.6em;
    padding: 0;
    display: inline-block;
}

nav li {
    display: inline-block;
    margin: 0 0 0 1.6em;
}

nav li a {
    display: block;
    padding: .1em .2em 5px;
    text-decoration: none;
    color: #333;
    font-size: 1.1em;
    transition: border-bottom-color .218ms ease-in;
    transform-origin: center center;
    letter-spacing: 0.2rem;
    font-weight: bold;
    border-bottom: 3px solid transparent;
}

nav li a:hover,
nav li.active a {
    border-bottom-color: #333;
}

@media screen and (max-width: 480px) {
    nav {
        display: none;
    }
}