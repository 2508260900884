.contact__email{
    font-size: 2.5rem;
}

a.instagram:hover {
    color: #5851DB;
}
a.linkedin:hover {
    color: #0077B5;
}
a.email:hover {
    color: #D44638;
}
