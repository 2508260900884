#main {
    padding: 3rem 0;
    background: white;
    transition: all 400ms ease-in-out;
    z-index: 2;
    right: 0;
    left: 0;
    min-height: calc(100vh - 8rem);
}

#main > div {
    position: relative;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    content: "";
    clear: both;
    display: table;
}

.fade-out {
    opacity: 0.01;
}

.arrow-down {
    position: fixed;
    left: 4%;
    bottom: 4%;
    transition: opacity .3s ease-out;
}

.image-responsive {
    max-width: 100%;
    height: auto;
    visibility: visible;
}

@media screen and (max-width: 1280px) {

    .container {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {

    .container {
        width: 90%;
    }

    #main {
        padding-top: 0;
        padding-bottom: 4rem;
    }

    .arrow-down {
        display: none;
    }
}