.portfolio-item {
    display: block;
    min-width: 50%;
    position: relative;
    padding-bottom: 5vh;
    padding-top: 20vh;
    width: 56%;
}

.portfolio-list > .portfolio-item:nth-child(even)  {
    float: left;
    margin-left: 4%;
}

.portfolio-list > .portfolio-item:nth-child(odd)  {
    float: right;
}

.portfolio-item__title {
    margin: 0 auto 0 -1em;
    font-size: 4em;
    position: absolute;
    z-index: 2;
    font-weight: bold;
    color: #333;
    background: white;
    padding: 0.2em 0.4em 0.2em 0.2em;
}

.portfolio-item__title:hover {
    border-bottom: transparent;
}

.portfolio-item__image {
    transition: all 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.portfolio-item__image:hover {
    opacity: .9;
}

@media screen and (max-width: 1280px) {

    .portfolio-item__title {
        font-size: 3em;
    }

    .portfolio-item {
        padding-bottom: 5vh;
        padding-top: 10vh;
        width: 80%;
    }
}

@media screen and (max-width: 480px) {

    .portfolio-item__title {
        font-size: 2em;
        margin: 0;
    }

    .portfolio-item {
        width: 100%;
        padding-bottom: 15vh;
        padding-top: 5vh;
    }

    .portfolio-list > .portfolio-item:nth-child(even){
        margin-left: 0;
    }
}