
.prevnext-nav {
    position: fixed;
    bottom: 0;
    height: 3.5rem;
    background: white;
    left: 0;
    right: 0;
    box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.18);
    display: flex;
    text-align: center;
    transition: all 400ms ease-in;
    transform: translateY(0);
}

.prevnext-nav.prevnext-hidden{
    transition: all 400ms ease-out;
    transform: translateY(100px);
}

.prevnext-nav__label {
    padding: 0 1em;
    flex: 1;
}

.prevnext-nav__prev, .prevnext-nav__next {
    font-size: 2em;
    text-decoration: none;
    color: #333;
    Font-Family: 'Raleway', Sans-Serif;
    width: 10vh;
}

.prevnext-nav__label,
.prevnext-nav__prev,
.prevnext-nav__next {
    display: table;
    height: 100%;
}

.prevnext-nav__label span,
.prevnext-nav__prev a, .prevnext-nav__next a {
    display: table-cell;
    vertical-align: middle;
}