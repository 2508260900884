
.project-item__title {
    margin-bottom: 2rem;
}

.project-item__block {
    width: 66%;
    display: block;
    margin: 10vh 0 5vh;
}

.project-item__block:nth-child(even) {
    float: left;
}

.project-item__block:nth-child(odd) {
    float: right;
}

.project-item__block--text {
    width: 50%;
    border: 3px solid rgba(197, 217, 245, 0.28);
    padding: 2em;
}
.project-item__block--text p{
    margin:0;
}


@media screen and (max-width: 720px) {
    .project-item__block {
        float: none;
        width: auto;
        max-width: 100%;
        margin: 5% 0;
    }
    .project-item__block--text{
        width: auto;
        border:0;
    }
}
@media screen and (min-width: 721px) {
    .project-item__block.project-item__block--text:nth-child(2){
        width: 31%;
        border: none;
        padding: 0 3em 0 0;
    }
}